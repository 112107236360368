var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("br"),
    _vm._m(0),
    _c("br"),
    _c("br"),
    _c("table", { staticClass: "table" }, [
      _c("tbody", [
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [_vm._v("Numero: ")]),
          _c("td", { staticClass: "risposta" }, [
            _vm._v(_vm._s(_vm.getNumeroPratica)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [_vm._v("Area: ")]),
          _c("td", { staticClass: "risposta" }, [_vm._v(_vm._s(_vm.area))]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [_vm._v("Data di creazione: ")]),
          _c("td", { staticClass: "risposta" }, [
            _vm._v(_vm._s(_vm.getDataCreazionePratica)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [
            _vm._v("Identificativo unità operativa: "),
          ]),
          _c("td", { staticClass: "risposta" }, [
            _vm._v(_vm._s(_vm.getIDUnitaOperativa)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [
            _vm._v("Stato corrente della Pratica: "),
          ]),
          _c("td", { staticClass: "risposta" }, [
            _vm._v(_vm._s(_vm.getStatoCorrentePratica)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [
            _vm._v("Annotazione corrente sulla Pratica: "),
          ]),
          _c("td", { staticClass: "risposta" }, [
            _vm._v(_vm._s(_vm.annotazione)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [_vm._v("Nuovo Stato: ")]),
          _c(
            "td",
            [
              _c("q-select", {
                attrs: {
                  outlined: "",
                  options: _vm.getElencoStatiPratica(),
                  label: "Seleziona il nuovo stato della pratica",
                },
                model: {
                  value: _vm.model,
                  callback: function ($$v) {
                    _vm.model = $$v
                  },
                  expression: "model",
                },
              }),
            ],
            1
          ),
        ]),
        _c("tr", [
          _c("td", { staticClass: "domanda" }, [_vm._v("Note: ")]),
          _c(
            "td",
            [
              _c("q-input", {
                staticStyle: { border: "1px SOLID #C0C0C0" },
                attrs: { type: "textarea" },
                model: {
                  value: _vm.note,
                  callback: function ($$v) {
                    _vm.note = $$v
                  },
                  expression: "note",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _c("br"),
    _c("hr"),
    _c("div", { staticClass: "row justify-center" }, [
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "Torna al menu",
              color: "blue-grey",
              icon: "undo",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onIndietroClicked.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-md-4", attrs: { align: "center" } },
        [
          _c("QQButton", {
            attrs: {
              label: "Registra cambio stato",
              color: "blue-grey",
              icon: "save",
              size: "md",
            },
            nativeOn: {
              click: function ($event) {
                return _vm.onRegistraNuovoStato.apply(null, arguments)
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
    _c("br"),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Modifica lo stato della pratica"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }